<template>
    <v-sheet class="pa-5">
      <v-switch
        v-model="switch1"
        inset
        :label="`Switch 1: ${switch1.toString()}`"
        @change="toggleDarkTheme"
      ></v-switch>
    </v-sheet>
  </template>
  
  <script>
  export default {
    data() {
      return {
        switch1: this.$vuetify.theme.dark, // Инициализируем значение switch1 значением dark темы
      };
    },
    methods: {
      toggleDarkTheme() {
        this.switch1 = !this.switch1; // Инвертируем значение switch1
        this.$vuetify.theme.dark = this.switch1; // Присваиваем значение dark темы
      },
    },
  };
  </script>